/* stylelint-disable-next-line import-notation */
@import 'tailwindcss';

@custom-variant dark {
    @media not print {
        .dark & {
            @slot;
        }
    }
}

@theme {
    /* tailwind config */
    --spacing: 0.25rem;
    /* stylelint-disable-next-line custom-property-pattern */
    --breakpoint-*: initial;
    --breakpoint-sm: 576px;
    --breakpoint-md: 768px;
    --breakpoint-lg: 992px;
    --breakpoint-xl: 1200px;

    /* colors */
    --color-body: #3a3a3a;
    --color-box-shadow: #5e7684;
    --color-danger: #af3432;
    --color-dark-background: #162134;
    --color-dark-contrast: #253551;
    --color-dark-primary: #3e92cc;
    --color-gray-100: #3a3a3a;
    --color-gray-200: #9ea1ab;
    --color-gray-300: #cfd4dd;
    --color-gray-400: #f6f6f8;
    --color-info: #014785;
    --color-placeholder: #6c757d;
    --color-primary-100: #ccdae7;
    --color-primary-200: #7499b7;
    --color-primary-25: #f5f9fd;
    --color-primary-300: #277db3;
    --color-primary-400: #4872b5;
    --color-primary-50: #eaf4fa;
    --color-primary-500: #014785;
    --color-primary-600: #014078;
    --color-primary-700: #01396a;
    --color-primary-800: #012b50;
    --color-primary-900: #001c35;
    --color-primary-dark: #002158;
    --color-primary-light: #4872b5;
    --color-primary: #014785;
    --color-secondary-100: #ffd9a6;
    --color-secondary-200: #fec77a;
    --color-secondary-300: #feb44d;
    --color-secondary-400: #feb44d;
    --color-secondary-50: #ffecd3;
    --color-secondary-500: #fea121;
    --color-secondary-600: #e5911e;
    --color-secondary-700: #cb811a;
    --color-secondary-800: #b27117;
    --color-secondary-900: #986114;
    --color-secondary-dark: #c57200;
    --color-secondary-light: #feb249;
    --color-secondary: #fea121;
    --color-success: #32936f;
    --color-warning: #ffc100;
    --color-white: #fff;

    /* 'primary-50': #eaf4fa', // same as old */
    --color-redesign-primary-100: #d4e9f6;
    --color-redesign-primary-200: #99b5ce;
    --color-redesign-primary-300: #6791b6;
    --color-redesign-primary-400: #346c9d;

    /* 'primary-500': #014785', // same as old */
    --color-redesign-primary-600: #01396a;
    --color-redesign-primary-700: #012b50;
    --color-redesign-primary-800: #001c35;
    --color-redesign-primary-900: #000e1b;
    --color-redesign-secondary-100: #ffe6cc;
    --color-redesign-secondary-200: #fc9;
    --color-redesign-secondary-300: #ffb366;
    --color-redesign-secondary-400: #f93;
    --color-redesign-secondary-500: #ff8000;
    --color-redesign-secondary-600: #c60;
    --color-redesign-secondary-700: #994d00;
    --color-redesign-secondary-800: #630;
    --color-redesign-secondary-900: #331a00;
    --color-redesign-gray-50: #f6f6f8;
    --color-redesign-gray-200: #cfd4dd;
    --color-redesign-gray-300: #9ea1ab;
    --color-redesign-gray-400: #7b7e88;
    --color-redesign-gray-500: #56585f;
    --color-redesign-gray-900: #001c35;
    --color-redesign-warning-500: #ffc100;
    --color-redesign-danger-300: #f6bab7; /* muss noch verwendet werden */
    --color-redesign-danger-500: #e75750;
    --color-redesign-danger-600: #af3432;
    --color-redesign-info-50: #fff;
    --color-redesign-info-100: #d4e9f6;
    --color-redesign-info-500: #014785;
    --color-redesign-success-400: #00ff90; /* muss noch verwendet werden */
    --color-redesign-success-500: #00d176;
    --color-redesign-success-600: #32936f;
    --color-redesign-accent-green: #a8bf4e;
    --color-redesign-accent-turquoise: #8cc5c1;
    --color-redesign-accent-yellow: #f8d36d;
    --color-redesign-accent-purple: #715c9c;

    /* font face */
    --font-sans: 'Open Sans', 'sans-serif';
    --font-ci: 'Hind', 'sans-serif';

    /* font sizes */
    --text-xs: 0.8125rem;
    /* stylelint-disable-next-line custom-property-pattern */
    --text-xs--line-height: 1.846;
    --text-sm: 0.875rem;
    /* stylelint-disable-next-line custom-property-pattern */
    --text-sm--line-height: 1.71;
    --text-base: 0.9375rem;
    /* stylelint-disable-next-line custom-property-pattern */
    --text-base--line-height: 1.6;
    --text-lg: 1rem;
    --text-xl: 1.25rem;
    --text-2xl: 1.5rem;
    --text-3xl: 1.75rem;
    --text-4xl: 2.5rem;

    /* shadow */
    --shadow-calender: -10px -10px 20px 0 rgb(94 118 132 / 20%), 10px 10px 20px 0px rgb(94 118 132 / 30%); /* #5e7684 with 20% & 30% opacity() */
    --shadow-card: -10px -10px 20px 0 rgb(246 246 248 / 46%), 10px 10px 20px 0 rgb(94 118 132 / 30%); /* #5e7684 */
    --shadow-dropdown: 0 2px 4px 0 rgb(94 118 132 / 45%); /* #5e7684 */
    --shadow-default: 5px 5px 10px 0 rgb(94 118 132 / 30%); /* #5e7684 */
    --shadow-form-footer: 0 -2px 20px -10px rgb(94 118 132 / 45%); /* #5e7684 */
    --shadow-lg: 10px 10px 20px 0 rgb(94 118 132 / 30%); /* #5e7684 */
    --shadow-lg-active: 10px 10px 20px 0 rgb(94 118 132 / 60%); /* #5e7684 */
    --shadow-search-bar: 0 0 10px rgb(94 118 132 / 40%); /* #5e7684 */

    /* z-index */
    --z-fixed: 1030;
    --z-chat-button: 1039;
    --z-chat: 1040;
    --z-top-bar: 1045;
    --z-modal: 1055;
    --z-page-title: 50;
    --z-page-title-content: 51;

    /* animations & keyframes (keep them at the end of @theme, thx) */
    --animate-spinner: spinner 1.2s infinite;

    @keyframes spinner {
        0%,
        100% {
            box-shadow:
                0 -1.414em 0 -0.12em,
                1em -1em 0 -0.2em,
                1.414em 0 0 -0.5em,
                1em 1em 0 -0.5em,
                0 1.414em 0 -0.5em,
                -1em 1em 0 -0.5em,
                -1.414em 0 0 -0.4em,
                -1em -1em 0 -0.25em;
        }

        12.5% {
            box-shadow:
                0 -1.414em 0 -0.25em,
                1em -1em 0 -0.12em,
                1.414em 0 0 -0.2em,
                1em 1em 0 -0.5em,
                0 1.414em 0 -0.5em,
                -1em 1em 0 -0.5em,
                -1.414em 0 0 -0.5em,
                -1em -1em 0 -0.4em;
        }

        25% {
            box-shadow:
                0 -1.414em 0 -0.4em,
                1em -1em 0 -0.25em,
                1.414em 0 0 -0.12em,
                1em 1em 0 -0.2em,
                0 1.414em 0 -0.5em,
                -1em 1em 0 -0.5em,
                -1.414em 0 0 -0.5em,
                -1em -1em 0 -0.5em;
        }

        37.5% {
            box-shadow:
                0 -1.414em 0 -0.5em,
                1em -1em 0 -0.4em,
                1.414em 0 0 -0.25em,
                1em 1em 0 -0.12em,
                0 1.414em 0 -0.2em,
                -1em 1em 0 -0.5em,
                -1.414em 0 0 -0.5em,
                -1em -1em 0 -0.5em;
        }

        50% {
            box-shadow:
                0 -1.414em 0 -0.5em,
                1em -1em 0 -0.5em,
                1.414em 0 0 -0.4em,
                1em 1em 0 -0.25em,
                0 1.414em 0 -0.12em,
                -1em 1em 0 -0.2em,
                -1.414em 0 0 -0.5em,
                -1em -1em 0 -0.5em;
        }

        62.5% {
            box-shadow:
                0 -1.414em 0 -0.5em,
                1em -1em 0 -0.5em,
                1.414em 0 0 -0.5em,
                1em 1em 0 -0.4em,
                0 1.414em 0 -0.25em,
                -1em 1em 0 -0.12em,
                -1.414em 0 0 -0.2em,
                -1em -1em 0 -0.5em;
        }

        75% {
            box-shadow:
                0 -1.414em 0 -0.5em,
                1em -1em 0 -0.5em,
                1.414em 0 0 -0.5em,
                1em 1em 0 -0.5em,
                0 1.414em 0 -0.4em,
                -1em 1em 0 -0.25em,
                -1.414em 0 0 -0.12em,
                -1em -1em 0 -0.2em;
        }

        87.5% {
            box-shadow:
                0 -1.414em 0 -0.2em,
                1em -1em 0 -0.5em,
                1.414em 0 0 -0.5em,
                1em 1em 0 -0.5em,
                0 1.414em 0 -0.5em,
                -1em 1em 0 -0.4em,
                -1.414em 0 0 -0.25em,
                -1em -1em 0 -0.12em;
        }
    }
}

@layer base {
    :root {
        /* TODO: Use variables */
        --mat-autocomplete-background-color: #fff;
        --mat-menu-container-shape: 4px;
        --mdc-icon-button-state-layer-size: 32px;
        --mat-datepicker-calendar-date-selected-state-text-color: white;
        --mat-datepicker-calendar-date-selected-state-background-color: #014785;
        --mat-datepicker-calendar-date-selected-disabled-state-background-color: rgb(63 81 181 / 40%);
        --mat-datepicker-calendar-date-today-selected-state-outline-color: white;
        --mat-datepicker-toggle-active-state-icon-color: #3f51b5;
        --mat-datepicker-calendar-date-in-range-state-background-color: #7499b7;
        --mat-datepicker-calendar-date-in-comparison-range-state-background-color: rgb(249 171 0 / 20%);
        --mat-datepicker-calendar-date-in-overlap-range-state-background-color: #a8dab5;
        --mat-datepicker-calendar-date-in-overlap-range-selected-state-background-color: #46a35e;
        --mat-datepicker-toggle-icon-color: rgb(0 0 0 / 54%);
        --mat-datepicker-calendar-body-label-text-color: white;
        --mat-datepicker-calendar-period-button-icon-color: rgb(0 0 0 / 54%);
        --mat-datepicker-calendar-navigation-button-icon-color: rgb(0 0 0 / 54%);
        --mat-datepicker-calendar-header-text-color: #3a3a3a;
        --mat-datepicker-calendar-date-today-outline-color: #ccdae7;
        --mat-datepicker-calendar-date-today-disabled-state-outline-color: rgb(0 0 0 / 18%);
        --mat-datepicker-calendar-date-text-color: rgb(0 0 0 / 87%);
        --mat-datepicker-calendar-date-outline-color: transparent;
        --mat-datepicker-calendar-date-disabled-state-text-color: rgb(0 0 0 / 38%);
        --mat-datepicker-calendar-date-preview-state-outline-color: #ccdae7;
        --mat-datepicker-range-input-separator-color: rgb(0 0 0 / 87%);
        --mat-datepicker-range-input-disabled-state-separator-color: rgb(0 0 0 / 38%);
        --mat-datepicker-range-input-disabled-state-text-color: rgb(0 0 0 / 38%);
        --mat-datepicker-calendar-container-background-color: white;
        --mat-datepicker-calendar-container-text-color: rgb(0 0 0 / 87%);
        --mat-full-pseudo-checkbox-selected-icon-color: #ff4081;
        --mat-full-pseudo-checkbox-selected-checkmark-color: #fafafa;
        --mat-full-pseudo-checkbox-unselected-icon-color: rgb(0 0 0 / 54%);
        --mat-full-pseudo-checkbox-disabled-selected-checkmark-color: #fafafa;
        --mat-full-pseudo-checkbox-disabled-unselected-icon-color: #b0b0b0;
        --mat-full-pseudo-checkbox-disabled-selected-icon-color: #b0b0b0;
        --mat-minimal-pseudo-checkbox-selected-checkmark-color: #ff4081;
        --mat-minimal-pseudo-checkbox-disabled-selected-checkmark-color: #b0b0b0;
        --mat-ripple-color: rgb(0 0 0 / 10%);
        --mat-option-selected-state-label-text-color: #3f51b5;
        --mat-option-label-text-color: rgb(0 0 0 / 87%);
        --mat-option-hover-state-layer-color: rgb(0 0 0 / 4%);
        --mat-option-focus-state-layer-color: rgb(0 0 0 / 4%);
        --mat-option-selected-state-layer-color: rgb(0 0 0 / 4%);
        --mat-optgroup-label-text-color: rgb(0 0 0 / 87%);
    }

    :root.dark {
        --mat-autocomplete-background-color: var(--color-primary);
        --mat-menu-container-shape: 4px;
        --mdc-icon-button-state-layer-size: 32px;
        --mdc-icon-button-disabled-icon-color: var(--color-redesign-primary-300);
        --mat-datepicker-calendar-date-selected-state-text-color: white;
        --mat-datepicker-calendar-date-selected-state-background-color: var(--color-redesign-primary-600);
        --mat-datepicker-calendar-date-selected-disabled-state-background-color: rgb(63 81 181 / 40%);
        --mat-datepicker-calendar-date-today-selected-state-outline-color: white;
        --mat-datepicker-toggle-active-state-icon-color: #3f51b5;
        --mat-datepicker-calendar-date-in-range-state-background-color: #7499b7;
        --mat-datepicker-calendar-date-in-comparison-range-state-background-color: rgb(249 171 0 / 20%);
        --mat-datepicker-calendar-date-in-overlap-range-state-background-color: #a8dab5;
        --mat-datepicker-calendar-date-in-overlap-range-selected-state-background-color: #46a35e;
        --mat-datepicker-toggle-icon-color: rgb(0 0 0 / 54%);
        --mat-datepicker-calendar-body-label-text-color: var(--color-primary);
        --mat-datepicker-calendar-period-button-icon-color: var(--color-primary-50);
        --mat-datepicker-calendar-period-button-text-color: var(--color-primary-50);
        --mat-datepicker-calendar-navigation-button-icon-color: var(--color-primary-50);
        --mat-datepicker-calendar-header-text-color: var(--color-primary-50);
        --mat-datepicker-calendar-date-today-outline-color: #ccdae7;
        --mat-datepicker-calendar-date-today-disabled-state-outline-color: rgb(0 0 0 / 18%);
        --mat-datepicker-calendar-date-text-color: var(--color-primary-50);
        --mat-datepicker-calendar-date-outline-var: transparent;
        --mat-datepicker-calendar-date-disabled-state-text-color: rgb(0 0 0 / 38%);
        --mat-datepicker-calendar-date-preview-state-outline-color: #ccdae7;
        --mat-datepicker-range-input-separator-color: rgb(0 0 0 / 87%);
        --mat-datepicker-range-input-disabled-state-separator-color: rgb(0 0 0 / 38%);
        --mat-datepicker-range-input-disabled-state-text-color: rgb(0 0 0 / 38%);
        --mat-datepicker-calendar-container-background-color: var(--color-primary);
        --mat-datepicker-calendar-container-text-color: rgb(0 0 0 / 87%);
        --mat-full-pseudo-checkbox-selected-icon-color: #ff4081;
        --mat-full-pseudo-checkbox-selected-checkmark-color: #fafafa;
        --mat-full-pseudo-checkbox-unselected-icon-color: rgb(0 0 0 / 54%);
        --mat-full-pseudo-checkbox-disabled-selected-checkmark-color: #fafafa;
        --mat-full-pseudo-checkbox-disabled-unselected-icon-color: #b0b0b0;
        --mat-full-pseudo-checkbox-disabled-selected-icon-color: #b0b0b0;
        --mat-minimal-pseudo-checkbox-selected-checkmark-color: #ff4081;
        --mat-minimal-pseudo-checkbox-disabled-selected-checkmark-color: #b0b0b0;
        --mat-ripple-color: rgb(0 0 0 / 10%);
        --mat-option-selected-state-label-text-color: #3f51b5;
        --mat-option-label-text-color: var(--color-primary-50);
        --mat-option-hover-state-layer-color: rgb(0 0 0 / 4%);
        --mat-option-focus-state-layer-color: rgb(0 0 0 / 4%);
        --mat-option-selected-state-layer-color: rgb(0 0 0 / 4%);
        --mat-optgroup-label-text-color: var(--color-primary-50);
    }

    /* BOOTSTRAP: refactor page */
    body {
        @apply text-body;
        @apply bg-white;
        @apply font-sans;
        @apply min-h-screen;
        @apply text-base;

        @variant dark {
            @apply bg-redesign-primary-600;
            @apply text-primary-50;
        }
    }

    h1,
    .h1,
    h2,
    .h2,
    h3,
    .h3,
    h4,
    .h4,
    h5,
    .h5 {
        @apply mb-4;
        @apply font-ci;
        @apply text-primary;
        @apply font-medium;
        @apply leading-[1.2];

        @variant dark {
            @apply text-white;
        }
    }

    h1,
    .h1 {
        font-size: calc(1.7719rem + 0.9707vw);

        @apply xl:text-4xl;
    }

    h2,
    .h2 {
        font-size: calc(1.4587rem + 0.3883vw);

        @apply xl:text-3xl;
    }

    h3,
    .h3 {
        font-size: calc(1.3543rem + 0.1941vw);

        @apply xl:text-2xl;
    }

    h4,
    .h4 {
        @apply text-xl;
    }

    h5,
    .h5 {
        @apply text-lg;
    }

    /* bootstrap legacy: remove together with "float-left" on all legend tags */
    legend + * {
        @apply clear-left;
    }

    a {
        @apply underline;

        @variant dark {
            @apply text-white;
        }
    }

    a:hover,
    a:active,
    a:focus {
        @apply text-primary-light;
    }

    a:focus-visible {
        @apply outline-offset-[5px] outline-primary;
    }

    button,
    div {
        @apply outline-primary;
    }

    button,
    [role='button'] {
        @apply cursor-pointer;
    }

    p,
    ul {
        margin-top: 0;
        margin-bottom: 1rem;
    }

    input {
        @variant dark {
            @apply bg-primary;
            @apply border-primary-400;
        }
    }

    ol ol,
    ul ul,
    ol ul,
    ul ol {
        @apply mb-0;
    }

    th {
        @apply text-start;
        @apply font-medium;
    }

    fieldset {
        min-inline-size: unset;
    }

    .bg-default {
        @apply bg-white;

        @variant dark {
            @apply bg-redesign-primary-800;
        }
    }

    .bg-alt {
        @apply bg-gray-400;

        @variant dark {
            @apply bg-primary;
        }
    }

    .bg-contrast {
        @apply bg-gray-300;

        @variant dark {
            @apply bg-primary;
        }
    }

    .container {
        @apply print:max-w-[unset]!;
    }

    .break-long-words {
        overflow-wrap: anywhere;
    }

    .mat-sidenav {
        @apply bg-gray-400! w-full! md:w-[480px]!;

        @variant dark {
            @apply bg-dark-contrast!;
        }
    }
}

@utility container {
    margin-inline: auto;
    padding-inline: 16px;

    @media (width >= theme(--breakpoint-md)) {
        @apply max-w-[690px];
    }

    @media (width >= theme(--breakpoint-lg)) {
        @apply max-w-[930px];
    }

    @media (width >= theme(--breakpoint-xl)) {
        @apply max-w-[1100px];
    }
}

@utility form-control {
    /*
    Form Controls (used by libs/form, lib/service-tools, etc.)
    Input & Selects
    */
    @apply appearance-none;
    @apply bg-white;
    @apply block;
    @apply border-2;
    @apply border-gray-300;
    @apply px-4;
    @apply py-[0.825rem];
    @apply rounded-md;
    @apply text-[1rem];
    @apply text-gray-100;
    @apply transition-[border-color];
    @apply transition-[box-shadow];
    @apply w-full;

    @variant focus {
        @apply border-gray-200;
        @apply outline-hidden;
        @apply shadow-none;
    }

    @variant placeholder {
        @apply text-gray-100/75;
    }

    @variant disabled {
        @apply cursor-default;
        @apply text-gray-200;
    }

    &:not(.form-select) {
        @variant read-only {
            @apply cursor-default;
            @apply text-gray-200;
        }
    }

    @variant dark {
        @apply bg-primary-500;
        @apply border-redesign-primary-400;
        @apply text-primary-50;

        @variant hover {
            @apply border-redesign-primary-300;
            @apply read-only:border-redesign-primary-400;
        }

        @variant focus {
            @apply border-white;
        }

        @variant placeholder {
            @apply text-primary-50;
        }

        @variant disabled {
            @apply border-redesign-primary-400;
        }

        &:not(.form-select) {
            @variant read-only {
                @apply bg-redesign-primary-600;
                @apply text-primary-50;
            }
        }
    }

    /* Hide Edge's Reveal icon */

    /* TODO: kann evtl. raus wegen chromium */
    &[type='password']::-ms-clear,
    &[type='password']::-ms-reveal {
        @apply hidden;
    }

    &.is-valid:not(:disabled) {
        @apply border-redesign-success-600;

        @variant dark {
            @apply border-redesign-success-500;
        }
    }

    &.is-invalid:not(:disabled) {
        @apply border-redesign-danger-600;

        @variant dark {
            @apply border-redesign-danger-500;
        }
    }
}

@utility form-select {
    @apply form-control;
    @apply bg-no-repeat;
    @apply bg-[right_1rem_center];
    @apply pl-4;
    @apply pr-12;

    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='22px' height='22px' viewBox='0 0 128 128'>%3cpath d='M64 95.34a9 9 0 0 1-6.36-2.63L4.47 39.54a5 5 0 0 1 7.07-7.08L64 84.93l52.47-52.47a5 5 0 0 1 7.07 7.08L70.36 92.71A9 9 0 0 1 64 95.34z' fill='rgb(1, 71, 133)' />%3c/svg>");

    @variant dark {
        @apply text-white;

        background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='22px' height='22px' viewBox='0 0 128 128'%3e%3cpath d='M64 95.34a9 9 0 0 1-6.36-2.63L4.47 39.54a5 5 0 0 1 7.07-7.08L64 84.93l52.47-52.47a5 5 0 0 1 7.07 7.08L70.36 92.71A9 9 0 0 1 64 95.34z' fill='rgb(255, 255, 255)' />%3c/svg>");

        @variant placeholder {
            @apply text-primary-50;

            background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='22px' height='22px' viewBox='0 0 128 128'%3e%3cpath d='M64 95.34a9 9 0 0 1-6.36-2.63L4.47 39.54a5 5 0 0 1 7.07-7.08L64 84.93l52.47-52.47a5 5 0 0 1 7.07 7.08L70.36 92.71A9 9 0 0 1 64 95.34z' fill='rgb(255, 255, 255)' />%3c/svg>");
        }
    }

    @variant placeholder {
        @apply text-gray-100/75;
    }

    &[disabled],
    &.disabled {
        @apply text-gray-200;

        background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='22px' height='22px' viewBox='0 0 128 128'>%3cpath d='M64 95.34a9 9 0 0 1-6.36-2.63L4.47 39.54a5 5 0 0 1 7.07-7.08L64 84.93l52.47-52.47a5 5 0 0 1 7.07 7.08L70.36 92.71A9 9 0 0 1 64 95.34z' fill='rgb(229 231 235)' />%3c/svg>");
    }
}

@utility form-check {
    /* Radios */
    @apply block;
    @apply mb-[.125rem];
    @apply min-h-6;
    @apply pl-6;

    & .form-check-input {
        @apply float-left;
        @apply -ml-6;
    }
}

@utility form-check-input {
    @apply align-top;
    @apply appearance-none;
    @apply bg-white;
    @apply border-gray-300;
    @apply border-solid;
    @apply border-2;
    @apply h-4;
    @apply mt-1;
    @apply w-4;
    @apply bg-no-repeat;
    @apply bg-center;
    @apply bg-contain;

    .form-check & {
        @apply float-left;
        @apply -ml-6;
    }

    &[type='radio'] {
        @apply rounded-[50%];
    }

    &[type='radio']:checked {
        @apply bg-primary;
        @apply border-primary;

        background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='2' fill='rgb(255, 255, 255)'/%3e%3c/svg%3e");
    }

    &[type='radio'].is-valid {
        @apply border-redesign-success-600;
    }

    .form-check-input[type='radio'].is-invalid {
        @apply border-redesign-danger-600;
    }
}

@utility cdk-overlay-container {
    @apply print:relative;
    @apply z-(--z-modal)!;

    /* Hide app content while modals are open */
    body:has(&:not(:empty)) big-root {
        @apply print:hidden;
    }
}

@utility cdk-global-overlay-wrapper {
    @apply print:justify-start! print:items-start! print:relative!;
}

@utility ng-overlay-container {
    @apply bg-white! shadow-lg! rounded-lg!;
    @apply [&_#nav-user-content]:overflow-hidden [&_#nav-user-content]:max-h-[calc(100vh-275px)];
    @apply print:shadow-none! print:[&_#nav-user-content]:max-h-none print:relative;

    @variant dark {
        @apply bg-dark-contrast!;
        @apply shadow-none!;
    }
}

@utility embedded-modal-panel {
    @apply md:w-[916.667px]! w-[calc(100%-32px)]! overflow-hidden rounded-md!;
}

@utility form-close-modal-panel {
    @apply absolute! top-32 md:w-[730px]! w-[calc(100%-32px)]! overflow-hidden rounded-md!;
}

@utility information-modal-panel {
    @apply absolute! top-1/2 -translate-y-1/2 md:w-[730px]! w-[calc(100%-32px)]! overflow-auto rounded-md! max-h-[70vh];
    @apply print:w-full! print:relative! print:translate-y-0;
}

@utility nav-modal-single-column {
    @apply min-w-[340px];
    @apply max-w-[400px];
}

@utility nav-modal-multi-column {
    @apply min-w-[616px];
    @apply max-w-[800px];
}

@utility mat-drawer-container {
    @apply min-h-screen;
}

@utility mat-mdc-optgroup-label {
    @apply font-bold text-primary;
}

@utility mat-mdc-option {
    @apply py-[6px]!;

    @variant hover {
        @apply bg-gray-400!;
        @apply px-2!;
        @apply mx-2!;
        @apply rounded-md;
        @apply text-primary-300;
    }

    .mat-mdc-optgroup &:not(.mat-mdc-option-multiple) {
        @apply px-4!;
    }

    /* stylelint-disable-next-line selector-class-pattern */
    &.mdc-list-item--disabled .mdc-list-item__primary-text {
        @apply opacity-100!;
    }

    .mat-mdc-optgroup &:hover:not(.mat-mdc-option-multiple) {
        @apply bg-gray-400! px-2! mx-2! rounded-md text-primary-300;
    }
}

@utility mat-mdc-optgroup {
    & .mat-mdc-option:not(.mat-mdc-option-multiple) {
        @apply px-4!;
    }

    & .mat-mdc-option:hover:not(.mat-mdc-option-multiple) {
        @apply bg-gray-400! px-2! mx-2! rounded-md text-primary-300;
    }
}

@utility mdc-list-item--disabled {
    /* stylelint-disable-next-line selector-class-pattern */
    &.mat-mdc-option .mdc-list-item__primary-text {
        @apply opacity-100!;
    }
}

@utility mdc-list-item__primary-text {
    /* stylelint-disable-next-line selector-class-pattern */
    .mat-mdc-option.mdc-list-item--disabled & {
        @apply opacity-100!;
    }
}

@utility mat-mdc-option-active {
    /* stylelint-disable-next-line selector-class-pattern */
    &:not(.mdc-list-item--disabled) {
        @apply bg-gray-400! px-2! mx-2! rounded-md text-primary-300;
    }
}

@utility cdk-visually-hidden {
    /*
    Material Datepicker Styles
    To further decrease the amount of unused css,
    we write the classes normally applied through the theme ourself!
    */
    @apply sr-only;
}

@utility mat-date-range-input-separator {
    @apply opacity-100!;
    @apply text-gray-200!;
}

@utility mat-date-range-input-separator-hidden {
    @apply opacity-100!;
    @apply text-gray-200!;
}

@utility mat-ripple {
    @apply relative;
    @apply overflow-hidden;

    &.mat-ripple-unbounded {
        @apply overflow-visible;
    }
}

@utility mat-ripple-unbounded {
    &.mat-ripple {
        @apply overflow-visible;
    }
}

@utility mat-datepicker-content {
    @apply shadow-calender!;
    @apply h-auto!;

    @variant dark {
        @apply shadow-none!;
    }
}

@utility mat-calendar-content {
    @apply p-6!;

    & .mat-calendar-body-in-preview .mat-calendar-body-cell-preview {
        @apply border-solid;
        @apply border-y-2;
    }
}

@utility mat-calendar-body-cell-content {
    @apply rounded-sm!;
    @apply border-2!;

    button:not(.mat-calendar-body-in-range) & {
        @apply hover:bg-gray-400!;

        @variant dark {
            @apply hover:bg-redesign-primary-400!;
        }
    }

    .mat-calendar-body-cell-container .mat-calendar-body-preview-end & {
        @apply bg-gray-400!;
        @apply hover:border-y-2!;
        @apply hover:border-l-0!;
        @apply hover:border-[#ccdae7];
    }
}

@utility mat-calendar-body-selected {
    @apply bg-primary!;
}

@utility mat-calendar-body {
    /* We can't hide this element, otherwise the datepicker will destroy itself :D */
    & .mat-calendar-body-label {
        @apply pt-0!;
        @apply pb-0!;
    }
}

@utility mat-calendar-body-label {
    /* We can't hide this element, otherwise the datepicker will destroy itself :D */
    .mat-calendar-body & {
        @apply pt-0!;
        @apply pb-0!;
    }
}

@utility mat-focus-indicator {
    &::before {
        @apply absolute top-0 bottom-0 left-0 right-0 pointer-events-none rounded-xs;
    }

    &:focus::before {
        @apply content-[''];
    }
}

@utility mat-mdc-focus-indicator {
    &::before {
        @apply absolute top-0 bottom-0 left-0 right-0 pointer-events-none rounded-xs;
    }

    &:focus::before {
        --mat-focus-indicator-display: block;
    }

    @apply relative;
}

@utility cdk-high-contrast-active {
    --mat-focus-indicator-display: block;
    --mat-mdc-focus-indicator-display: block;
}

@utility mat-calendar-controls {
    @apply my-0!;
    @apply hidden;

    /*
    Material sets a width and height value of 40px here using this "--mdc-icon-button-state-layer-size" variable,
    applying those styles here breaks the calendar control box inside the calendar header.
    */
    & .mat-mdc-icon-button.mat-mdc-button-base {
        @apply p-2;
    }
}

@utility mat-date-range-seperator {
    @apply opacity-100;
}

@utility mat-mdc-icon-button {
    &.mat-mdc-button-base {
        @apply hidden;
    }

    &.mat-mdc-button-base.mat-calendar-previous-button {
        @apply block;
    }

    &.mat-mdc-button-base.mat-calendar-next-button {
        @apply block;
    }

    & .mat-mdc-button-touch-target {
        @apply h-3;
        @apply w-3;
    }

    &.mat-mdc-button-base.mat-calendar-previous-button::after {
        @apply h-3;
        @apply w-3;
    }

    &.mat-mdc-button-base.mat-calendar-next-button::after {
        @apply h-3;
        @apply w-3;
    }

    /*
    Material sets a width and height value of 40px here using this "--mdc-icon-button-state-layer-size" variable,
    applying those styles here breaks the calendar control box inside the calendar header.
    */
    .mat-calendar-controls &.mat-mdc-button-base {
        @apply p-2;
    }
}

@utility mat-mdc-button-base {
    & .mat-mdc-icon-button {
        @apply hidden;
    }

    &.mat-mdc-icon-button.mat-calendar-previous-button {
        @apply block;
    }

    & .mat-mdc-icon-button.mat-calendar-next-button {
        @apply block;
    }

    &.mat-mdc-icon-button.mat-calendar-previous-button::after {
        @apply h-3;
        @apply w-3;
    }

    & .mat-mdc-icon-button.mat-calendar-next-button::after {
        @apply h-3;
        @apply w-3;
    }

    /*
    Material sets a width and height value of 40px here using this "--mdc-icon-button-state-layer-size" variable,
    applying those styles here breaks the calendar control box inside the calendar header.
    */
    .mat-calendar-controls &.mat-mdc-icon-button {
        @apply p-2;
    }
}

@utility mat-mdc-button-touch-target {
    @apply hidden;

    .mat-mdc-icon-button & {
        @apply h-3;
        @apply w-3;
    }
}

@utility mat-calendar-previous-button {
    &.mat-mdc-icon-button.mat-mdc-button-base {
        @apply block;
    }

    &.mat-mdc-icon-button.mat-mdc-button-base::after {
        @apply h-3;
        @apply w-3;
    }

    @apply mb-4!;

    &::after {
        @apply border-primary!;
        @apply rotate-45;
    }
}

@utility mat-calendar-next-button {
    & .mat-mdc-icon-button.mat-mdc-button-base {
        @apply block;
    }

    & .mat-mdc-icon-button.mat-mdc-button-base::after {
        @apply h-3;
        @apply w-3;
    }

    @apply mb-4!;

    &::after {
        @apply border-primary!;
        @apply rotate-45;
    }
}

@utility mat-theme-loaded-marker {
    @apply hidden;
}

@utility mat-app-background {
    @apply bg-zinc-50;
    @apply text-black/87;
}

@utility mat-calendar-table-header-divider {
    @apply hidden;
}

@utility mat-calendar-table {
    & .mat-calendar-table-header th {
        @apply pb-0;
        @apply text-lg;
        @apply font-semibold;
    }
}

@utility mat-calendar-table-header {
    .mat-calendar-table & th {
        @apply pb-0;
        @apply text-lg;
        @apply font-semibold;
    }
}

@utility mat-calendar-header {
    mat-calendar-header & {
        @apply px-6;
        @apply pt-6;
    }
}

@utility mat-calendar-body-cell-container {
    & .mat-calendar-body-preview-end .mat-calendar-body-cell-content {
        @apply bg-[#f6f6f8]!;
        @apply hover:border-y-2!;
        @apply hover:border-l-0!;
        @apply hover:border-[#ccdae7];
    }
}

@utility mat-calendar-body-preview-end {
    .mat-calendar-body-cell-container & .mat-calendar-body-cell-content {
        @apply bg-[#f6f6f8]!;
        @apply hover:border-y-2!;
        @apply hover:border-l-0!;
        @apply hover:border-[#ccdae7];
    }
}

@utility mat-calendar-body-in-preview {
    .mat-calendar-content & .mat-calendar-body-cell-preview {
        @apply border-solid;
        @apply border-y-2;
    }
}

@utility mat-calendar-body-cell-preview {
    .mat-calendar-content .mat-calendar-body-in-preview & {
        @apply border-solid;
        @apply border-y-2;
    }
}

@utility mdc-button__label {
    & span {
        @apply text-lg;
        @apply font-semibold;
    }

    & .mat-calendar-arrow {
        @apply m-0;
        @apply ml-2;
        @apply w-4;
        @apply h-4;
        @apply fill-primary;
    }
}

@utility mat-calendar-arrow {
    /* stylelint-disable-next-line selector-class-pattern */
    .mdc-button__label & {
        @apply m-0;
        @apply ml-2;
        @apply w-4;
        @apply h-4;
        @apply fill-primary;
    }
}

@utility mat-primary {
    --mat-full-pseudo-checkbox-selected-icon-color: #3f51b5;
    --mat-minimal-pseudo-checkbox-selected-checkmark-color: #3f51b5;
}

@utility mat-accent {
    --mat-option-selected-state-label-text-color: #ff4081;

    &.mat-datepicker-content {
        --mat-datepicker-calendar-date-selected-state-background-color: #ff4081;
        --mat-datepicker-calendar-date-selected-disabled-state-background-color: rgb(255 64 129 / 40%);
        --mat-datepicker-calendar-date-focus-state-background-color: rgb(255 64 129 / 30%);
        --mat-datepicker-calendar-date-hover-state-background-color: rgb(255 64 129 / 30%);
        --mat-datepicker-calendar-date-in-range-state-background-color: rgb(255 64 129 / 20%);
    }

    &.mat-datepicker-toggle-active {
        --mat-datepicker-toggle-active-state-icon-color: #ff4081;
    }
}

@utility mat-warn {
    --mat-full-pseudo-checkbox-selected-icon-color: #f44336;
    --mat-minimal-pseudo-checkbox-selected-checkmark-color: #f44336;
    --mat-option-selected-state-label-text-color: #f44336;

    &.mat-datepicker-content {
        --mat-datepicker-calendar-date-selected-state-background-color: #f44336;
        --mat-datepicker-calendar-date-selected-disabled-state-background-color: rgb(244 67 54 / 40%);
        --mat-datepicker-calendar-date-focus-state-background-color: rgb(244 67 54 / 30%);
        --mat-datepicker-calendar-date-hover-state-background-color: rgb(244 67 54 / 30%);
        --mat-datepicker-calendar-date-in-range-state-background-color: rgb(244 67 54 / 20%);
    }

    &.mat-datepicker-toggle-active {
        --mat-datepicker-toggle-active-state-icon-color: #f44336;
    }
}

@utility mat-datepicker-content {
    &.mat-accent {
        --mat-datepicker-calendar-date-selected-state-background-color: #ff4081;
        --mat-datepicker-calendar-date-selected-disabled-state-background-color: rgb(255 64 129 / 40%);
        --mat-datepicker-calendar-date-focus-state-background-color: rgb(255 64 129 / 30%);
        --mat-datepicker-calendar-date-hover-state-background-color: rgb(255 64 129 / 30%);
        --mat-datepicker-calendar-date-in-range-state-background-color: rgb(255 64 129 / 20%);
    }

    &.mat-warn {
        --mat-datepicker-calendar-date-selected-state-background-color: #f44336;
        --mat-datepicker-calendar-date-selected-disabled-state-background-color: rgb(244 67 54 / 40%);
        --mat-datepicker-calendar-date-focus-state-background-color: rgb(244 67 54 / 30%);
        --mat-datepicker-calendar-date-hover-state-background-color: rgb(244 67 54 / 30%);
        --mat-datepicker-calendar-date-in-range-state-background-color: rgb(244 67 54 / 20%);
    }
}

@utility mat-datepicker-toggle-active {
    &.mat-accent {
        --mat-datepicker-toggle-active-state-icon-color: #ff4081;
    }

    &.mat-warn {
        --mat-datepicker-toggle-active-state-icon-color: #f44336;
    }
}

@utility mdc-line-ripple {
    @apply hidden;
}
